
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ===================== FONTS ===================== */

h1 {
  font-family: "neulis-sans", sans-serif;
  font-weight: 600;
  font-style: normal;
}

h2, h3, h4, h5, h6 {
  font-family: "neulis-sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

a, p, span, bdi, form, input, textarea, button, input::placeholder, textarea::placeholder, label {
  font-family: "neulis-sans", sans-serif;
  font-weight: 300;
  font-style: normal;
}

/* ===================== LINKS ===================== */

.headerLink {
  color: white;
  position: relative;
  cursor: pointer;
}

.headerLink::before,
.headerLink::after {
  content: "";
  position: absolute;
  bottom: -3px;
  height: 2px;
  width: 0;
  background-color: white;
  transition: width 0.3s ease-in-out;
}

.headerLink::before {
  left: 0;
}

.headerLink::after {
  right: 0;
}

.headerLink:hover::before,
.headerLink:hover::after {
  width: 50%;
}

/* ===================== LINKS ===================== */

.cards {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .card-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1024px) {
  .card-container {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    gap: 25px;
  }
}
  
.card {
  position: relative;
  list-style: none;
  width: 300px;
  height: 70vh;
  transition: .3s ease;
  margin: 0px 10px;
}
  
.card:before {
  content: "";
  display: block;
  padding-bottom: 150%;
  width: 100%;
}
  
.card-background {
  background-size: cover;
  background-position: center;
  border-radius: 24px;
  bottom: 0;
  filter: brightness(0.75) saturate(1.2) contrast(0.85);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center;
  transform: scale(1) translateZ(0);
  transition: 
    filter 200ms linear,
    transform 200ms linear;
}
  
.card:hover .card-background {
  transform: scale(1.05) translateZ(0);
}
  
.card-container:hover .card:not(:hover) {
  filter: brightness(0.5) saturate(0) contrast(1.2) blur(20px);
}
  
.card-container:hover > .card:not(:hover) .card-category {
  filter: brightness(.5) saturate(0) contrast(1.2) blur(20px);
}
  
.card-category {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 5px;
  margin-bottom: 8px;
  text-transform: uppercase;
}
  
.card-heading {
  color: #ffffff;
  font-size: 1.5rem;
  text-shadow: 2px 2px 20px rgba(0,0,0,0.2);
  transform: rotate(-90deg) translateX(40%) translateY(-10vh);
}


/* ===================== MARQUEE ===================== */

.marquee {
  width: 100vw;
  overflow-x: hidden;
}

.track {
  white-space: nowrap;
}

.track > span {
  font-size: 4rem;
  opacity: 10%;
  color: #ffffff;
  text-transform: uppercase;
}

/* ===================== GRADIENT ===================== */

.testimonialGradient {
  background: rgb(9,9,11);
  background: -moz-linear-gradient(0deg, rgba(9,9,11,1) 2%, rgba(255,255,255,0) 90%);
  background: -webkit-linear-gradient(0deg, rgba(9,9,11,1) 2%, rgba(255,255,255,0) 90%);
  background: linear-gradient(0deg, rgba(9,9,11,1) 2%, rgba(255,255,255,0) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
}

.appsGradient {
  background: rgb(9, 9, 11) ;
  background: -moz-linear-gradient(0deg, rgba(9, 9, 11, 0.9) 0%, rgba(80,0,124,0) 50%, rgba(9, 9, 11, 0.9) 100%);
  background: -webkit-linear-gradient(0deg, rgba(9, 9, 11, 0.9) 0%, rgba(80,0,124,0) 50%, rgba(9, 9, 11, 0.9) 100%);
  background: linear-gradient(0deg, rgba(9, 9, 11, 0.9) 0%, rgba(80,0,124,0) 50%, rgba(9, 9, 11, 0.9) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

.heroAdditionalGradient {
  background: rgb(9, 9, 11) ;
  background: -moz-linear-gradient(0deg, rgba(9, 9, 11, 1) 0%, rgba(80,0,124,0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(9, 9, 11, 1) 0%, rgba(80,0,124,0) 100%);
  background: linear-gradient(0deg, rgba(9, 9, 11, 1) 0%, rgba(80,0,124,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#50007c",GradientType=1);
}

.heroGradient {
  background: rgb(9, 9, 11) ;
  background: -moz-linear-gradient(0deg, rgba(9, 9, 11, 1) 0%, rgba(79,0,122,0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(9, 9, 11, 1) 0%, rgba(79,0,122,0) 100%);
  background: linear-gradient(0deg, rgba(9, 9, 11, 1) 0%, rgba(79,0,122,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#51007d",endColorstr="#4f007a",GradientType=1);
}

/* ===================== BORDER ===================== */

.lightBorder {
  border: 0.5px solid #ffffff8e;
}

.slider div {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .slider div.containter {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 0px 25px;
  }
}

@media screen and (min-width: 1024px) {
  .slider div.containter {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    padding: 0px 25px;
  } 
}

.slick-dots li button:before {
  margin-top: 25px !important;
  color: #ffffff !important;
  transform: scale(2);
}

/* ===================== PROJECTS ===================== */

.projectOverlay {
  opacity: 50%;
  transition: 0.3s ease-in-out;
}

.projectTitle {
  display: none;
  transition: 0.3s ease-in-out;
}

.project:hover .projectOverlay {
  opacity: 80%;
}

.project:hover .projectTitle {
  display: block;
  transition: 0.3s ease-in-out;
}

